.slide-container {
  width: 250px;
  height: 250px;
  margin: auto;
}

.slide-container-left {
  position: relative;
  left: -70px;
}

.slide-container-right {
  position: relative;
  left: 30px;
}

.each-slide > img {
  height: 180px;
  width: 220px;
  object-fit: contain;
}

.directions {
  color: #b89535;
  cursor: pointer;
  position: relative;
  top: 20px;
}

.right-arrow {
  left: 6em;
}

.left-arrow {
  right: 1em;
}

.article-end {
  margin-right: 90px;
}

.article-start {
  margin-left: 90px;
}
