.home-main-carousel {
  width: 61%;
  height: 100%;
  margin-top: 80px;
}
.main-each-slide > img {
  width: 55%;
  height: 100%;
}

.main-each-slide {
  /* height: 100%; */
  width: 120%;
  display: flex;
}
@media screen and (max-width: 1024px) {
  .main-each-slide {
    height: auto;
    width: 120%;
    flex-direction: column;
  }
}

.main-slide-container {
  height: 100%;
  width: 100%;
}
.main-slide-container > div {
  height: 100%;
  width: 100%;
}
.main-slide-container > div > .react-slideshow-container {
  height: 100%;
  width: 100%;
}

.main-slide-container
  > div
  > .react-slideshow-container
  > .react-slideshow-fade-wrapper {
  height: 100%;
  width: 100%;
  overflow: visible;
}

.main-slide-container
  > div
  > .react-slideshow-container
  > .react-slideshow-fade-wrapper
  > .react-slideshow-fade-images-wrap {
  height: 100%;
  width: 80%;
}

.main-carousel-quote {
  text-align: start;
  color: black;
  font-weight: 300;
  font-size: 20px;
  width: 300px;
  overflow: visible;
}

.main-carousel-credentials > p {
  margin: 0;
  text-align: start;
  color: black;
  font-weight: 100;
}

.quote-icon {
  transform: rotate(180deg);
  color: #b89535;
  margin-left: -15px;
}

.main-carousel-article {
  position: relative;
  top: 15%;
  left: 60px;
  width: 30%;
}
