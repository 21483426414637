.description-video-container {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 0;
}

.video-gradient {
  height: 120px;
  width: 100vw;
  background-image: linear-gradient(
    0deg,
    rgba(68, 68, 68, 0.05646008403361347) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  position: relative;
  top: 0;
  z-index: -4;
}

.description-video {
  width: 100vw;
  margin-top: -120px;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: -5;
}
