.home-info-container {
  width: 100vw;
  min-height: 85vh;
  background-color: var(--h-white);
  background-image: url(../../assets/img/about/light_lines.svg);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.info-snippet-container {
  /* width: 60%;
  margin: auto; */
  display: flex;
  padding: 1rem;
  justify-content: space-around;
}

.info-snippet {
  display: flex;
}

.info-snippet-value {
  color: #b89535;
  font-size: 4rem;
  font-weight: 300;
  margin: 0;
}

.info-snippet-desc {
  font-size: 1rem;
  font-weight: 300;
  color: black;
  width: 220px;
  text-align: start;
}

@media only screen and (max-width: 1024px) {
  .info-snippet-container {
    flex-direction: column;
    margin: inherit;
    width: auto;
  }
  .info-snippet {
    flex-direction: column;
  }
}
