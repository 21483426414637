:root {
  --color-black: #1d1f22;
  --color-black-15a: rgba(29, 31, 34, 0.15);
  --color-black-20a: rgba(29, 31, 34, 0.2);
  --color-black-30a: rgba(29, 31, 34, 0.3);
  --color-black-90a: rgba(29, 31, 34, 0.9);
  --color-black-90: #343538;
  --color-black-75: #565759;
  --color-black-50: #8e8f90;
  --color-black-20: #d2d2d3;
  --color-black-10: #e2e0da;
  --color-cream: #f3efe5;
  --color-cream-50: #f5f3ea;
  --color-cream-25: #fcfbf8;
  --color-white: #fff;
  --color-white-10a: hsla(0, 0%, 100%, 0.1);
  --color-white-20a: hsla(0, 0%, 100%, 0.2);
  --color-white-30a: hsla(0, 0%, 100%, 0.3);
  --color-white-15a: hsla(0, 0%, 100%, 0.15);
  --color-real-black: #000;
  --color-deep-black: #161719;
  --color-deep-black-30a: rgba(22, 23, 25, 0.3);
  --color-deep-black-90a: rgba(22, 23, 25, 0.9);
  --color-light-grey: #f8f8f8;
  --color-gold: #ebc03f;
  --color-gold-50: #f5df9f;
  --color-gold-25: #faefcf;
  --color-dark-gold-20a: rgba(184, 139, 53, 0.2);
  --color-dark-gold: #b89535;
  --color-hotspots: #ffd02a;
  --color-bright-yellow: #ffd02a;
  --color-page-background: #f9f7f2;
  --color-mobile-language-selector-background: #111314;
  --color-ui-error: red;
  --color-ui-success: green;
  --color-dark-blue: #003865;
  --color-green: #006b44;
  --color-orange: #f18825;
  --color-light-blue: #007bb5;
  --color-red: #e0403f;
  --color-yellow: #ffe973;
  --color-dark-cerulean: #104878;
  --color-olive-drab: #628500;
  --color-rouge: #a0346c;
  --color-atoll: #2c818d;
  --color-shuttle-grey: #616366;
  --color-night-shadz: #a63b45;
  --color-sun-orange: #f29132;
  --color-observatory: #068c6e;
  --color-zorba: #9e8e80;
  --color-cannon-pink: #93506a;
  --color-cello: #34495e;
  --color-pine-cone: #796755;
  --color-blue-diamond: #4c2d67;
  --gradient-map-top: linear-gradient(
    0deg,
    rgba(29, 31, 34, 0),
    rgba(29, 31, 34, 0.8)
  );
  --gradient-map-bottom: linear-gradient(
    0deg,
    rgba(29, 31, 34, 0.8),
    rgba(29, 31, 34, 0)
  );
  --mask-background-color: rgba(0, 0, 0, 0.9);
  --button-shadow-color: rgba(0, 0, 0, 0.1);
  --containerMargin: 5.5555555556vw;
  --largeContainerMargin: 5.5555555556vw;
  --fluid-type-viewport-width-max: 1440px;
  --containerMaxWidth: var(--fluid-type-viewport-width-max);
  --grid-gap: 20px;
  --block-padding: 40px;
  --dropdown-navigation-offset: 140px;
  --page-indicator-color: var(--color-black-20a);
  --page-indicator-color-active: var(--color-dark-gold);
  --modal-background-color: var(--color-cream-50);
  --modal-mask-color: rgba(0, 0, 0, 0.75);
  --modal-heading-color: var(--color-black);
  --modal-text-color: var(--color-black-75);
  --input-placeholder-color: var(--color-black-50);
}

// SECTORS BUBBLE

.b-sectors {
  --sector-text-color: var(--color-black);
  height: 100%;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  // padding-bottom: 10vh;
}
.b-sectors__backgrounds {
  position: absolute;
}
.b-sectors__backgroundImage {
  opacity: 0;
  -webkit-transition: opacity 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: opacity 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: opacity 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.b-sectors__backgroundImage:after {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--color-black);
  content: "";
  opacity: 0.5;
  position: absolute;
}
.b-sectors__backgroundImage.-isActive {
  opacity: 1;
}
.b-sectors__container {
  margin: 0 auto;
  max-width: 1440px;
  padding: 60px 0;
  position: relative;
}
.b-sectors__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 10px;
  position: relative;
}
.b-sectors.-isActive {
  --sector-text-color: var(--color-white);
}
.b-sectors__row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.b-sectors__row.-compact {
  padding: var(--sector-grid-padding);
}
.b-sectors__placeholder {
  display: none;
}
.b-sectors .a-heading {
  color: var(--sector-text-color);
  margin: 0 0 50px;
  -webkit-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;
}
@media (min-width: 768px) {
  .b-sectors__content {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media (min-width: 1024px) {
  .b-sectors__content {
    display: block;
    padding: 0;
  }
  .b-sectors__row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
  }
  .b-sectors__placeholder {
    --sector-button-background-color: var(--color-black);
    --sector-button-border-color: transparent;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
  }
  .b-sectors__placeholder.-isDisabled {
    --sector-button-background-color: transparent;
    --sector-button-border-color: var(--color-white-20a);
  }
  .b-sectors__placeholderWrapper {
    height: 13vw;
    width: 13vw;
    max-height: 266px;
    max-width: 266px;
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: var(--sector-button-background-color);
    border: 2px solid var(--sector-button-border-color);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 0 15px;
    overflow: hidden;
    -webkit-transition: border-color 0.4s ease, background-color 0.4s ease,
      -webkit-transform 0.5s ease;
    transition: border-color 0.4s ease, background-color 0.4s ease,
      -webkit-transform 0.5s ease;
    -o-transition: border-color 0.4s ease, background-color 0.4s ease,
      transform 0.5s ease;
    transition: border-color 0.4s ease, background-color 0.4s ease,
      transform 0.5s ease;
    transition: border-color 0.4s ease, background-color 0.4s ease,
      transform 0.5s ease, -webkit-transform 0.5s ease;
  }
  .-isDisabled .b-sectors__placeholderWrapper {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }
  .b-sectors .m-sectorButton:not(:last-child),
  .b-sectors__placeholder:not(:last-child) {
    -webkit-margin-end: 34px;
    margin-inline-end: 34px;
  }
}

.m-sectorButton {
  --sector-button-background-color: var(--color-black);
  --sector-button-icon-color: var(--color-white);
  --sector-button-border-color: transparent;
  --sector-button-text-color: var(--color-black-20);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.m-sectorButton__container {
  height: 30vw;
  width: 30vw;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid var(--sector-button-border-color);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--sector-button-text-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 0 15px;
  max-height: 266px;
  max-width: 266px;
  overflow: hidden;
  position: relative;
  // -webkit-transition: border-color 0.4s ease, -webkit-transform 0.5s ease;
  // transition: border-color 0.4s ease, -webkit-transform 0.5s ease;
  // -o-transition: border-color 0.4s ease, transform 0.5s ease;
  // transition: border-color 0.4s ease, transform 0.5s ease;
  transition: all 0.5s ease;
}
.m-sectorButton__circle {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  fill: var(--sector-button-background-color);
  position: absolute;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.m-sectorButton__icon {
  --icon-size: 100%;
  color: var(--sector-button-icon-color);
  display: none;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin: auto;
  pointer-events: none;
  -webkit-transition: opacity 0.4s ease;
  -o-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  width: 33%;
  z-index: 1;
}
.m-sectorButton__icon svg {
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  fill: #fff;
}
.m-sectorButton__arrow {
  --icon-fill: var(--color-black);
  display: none;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.m-sectorButton__arrow .a-icon {
  opacity: 0;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease, opacity 0.3s ease;
  transition: transform 0.3s ease, opacity 0.3s ease;
  transition: transform 0.3s ease, opacity 0.3s ease,
    -webkit-transform 0.3s ease;
}
.m-sectorButton__label {
  font-weight: 400;
  letter-spacing: 0.06em;
  line-height: 120%;
  padding: 0 10%;
  pointer-events: none;
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: color 0.3s ease, opacity 0.6s ease;
  -o-transition: color 0.3s ease, opacity 0.6s ease;
  transition: color 0.3s ease, opacity 0.6s ease;
  z-index: 1;
  font-size: 9px;
}
@media screen and (min-width: 480px) {
  .m-sectorButton__label {
    font-size: calc(9px + 3 * (100vw - 480px) / 960);
  }
}
@media screen and (min-width: 1440px) {
  .m-sectorButton__label {
    font-size: 12px;
  }
}
@media (min-width: 480px) {
  .m-sectorButton__label {
    line-height: 120%;
    letter-spacing: 0.06em;
  }
}
[lang="ar"] .m-sectorButton__label {
  font-size: 12px;
}
@media screen and (min-width: 480px) {
  [lang="ar"] .m-sectorButton__label {
    font-size: calc(12px + 4 * (100vw - 480px) / 960);
  }
}
@media screen and (min-width: 1440px) {
  [lang="ar"] .m-sectorButton__label {
    font-size: 16px;
  }
}
.m-sectorButton.-isActive {
  --sector-button-background-color: var(--color-white);
  --sector-button-icon-color: var(--color-gold);
  --sector-button-text-color: var(--color-black);
  cursor: pointer;
}
.m-sectorButton.-isActive .m-sectorButton__arrow .a-icon {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.m-sectorButton.-isCurrent {
  --sector-button-background-color: var(--color-white);
  --sector-button-icon-color: var(--color-gold);
  --sector-button-text-color: var(--color-black);
  pointer-events: none;
}
.m-sectorButton.-isDisabled {
  --sector-button-background-color: transparent;
  --sector-button-border-color: var(--color-white-20a);
}
.m-sectorButton.-isDisabled .m-sectorButton__icon {
  opacity: 0.2;
}
.m-sectorButton.-isDisabled .m-sectorButton__label {
  opacity: 0;
}
@media (min-width: 768px) {
  .m-sectorButton__container {
    height: 13vw;
    width: 13vw;
  }
  .m-sectorButton__arrow,
  .m-sectorButton__icon {
    display: block;
  }
  .m-sectorButton__arrow {
    right: 40px;
  }
  .m-sectorButton__label {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 50%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    left: 50%;
    padding: 0 20%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .m-sectorButton__container {
    // height: 15.4vw;
    // width: 15.4vw;
    margin: 0;
  }
  .m-sectorButton.-isActive .m-sectorButton__container {
    -webkit-transform: scale(1.1) !important;
    -ms-transform: scale(1.1) !important;
    transform: scale(1.1) !important;
  }
  .m-sectorButton.-isDisabled .m-sectorButton__container {
    -webkit-transform: scale(0.8) !important;
    -ms-transform: scale(0.8) !important;
    transform: scale(0.8) !important;
  }
}
