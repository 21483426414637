.description-container {
  background-image: url("/images/homePage/description_image.jpeg");
  width: 100vw;
  background-color: white;
}

.description-content {
  text-align: left;
  margin: auto;
  width: 650px;
  padding: 100px 0;
}
@media screen and (max-width: 770px) {
  .description-content {
    width: auto;
  }
}

.description-heading {
  font-weight: 600;
  font-size: 50px;
  letter-spacing: 0.12em;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0px 15px;
}
